<template>
    <div class="p-contract-customer">
        <div class="hd">
            <div class="page-title">客户管理</div>
        </div>
        <div class="bd" v-loading="isLoading">
            <!-- <form-fields :fields="fields" :prop="fields"></form-fields> -->
            <div class="topbar">
                <div class="add">
                    <el-button
                        type="primary"
                        icon="el-icon-circle-plus-outline"
                        @click="onAdd"
                        >新增</el-button
                    >
                </div>
                <div class="search">
                    <el-input
                        placeholder="搜索"
                        v-model="search.keyword"
                        clearable
                    ></el-input>
                </div>
            </div>
            <div class="list">
                <el-table :data="list">
                    <el-table-column label="ID" prop="id"></el-table-column>
                    <el-table-column
                        label="客户名称"
                        prop="name"
                    ></el-table-column>
                    <el-table-column label="操作">
                        <template scope="scope">
                            <el-button
                                type="primary"
                                size="mini"
                                plain
                                @click="onEdit(scope.row)"
                                >编辑</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="ft"></div>
    </div>
</template>

<style lang="less">
.p-contract-customer {
    .bd {
        .topbar {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;

            & > div {
                padding-right: 10px;
            }
        }
    }
}
</style>

<script>
// import FormFields from '../components/form-fields.vue'

export default {
    components: {},
    data() {
        return {
            // fields: {
            // 	name: {
            // 		label: 'Name',
            // 		value: '1',
            // 		type: 'text',
            // 		rules: [ { required: true, } ]
            // 	},
            // 	x: {
            // 		label: 'X',
            // 		value: 'x',
            // 		type: 'text',
            // 		rules: [ { required: true, } ]
            // 	}
            // },
            ready: false,
            isLoading: false,
            search: {
                keyword: "",
            },
            customers: [],
        };
    },
    computed: {
        list() {
            let result = [];
            for (let i = 0; i < this.customers.length; i++) {
                let customer = this.customers[i];
                if (
                    this.search.keyword &&
                    customer.name
                        .toLowerCase()
                        .indexOf(this.search.keyword.toLowerCase()) < 0
                ) {
                    continue;
                }
                result.push(customer);
            }
            return result;
        },
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            let that = this;
            if (that.isLoading) {
                return;
            }
            that.isLoading = true;
            that.$ajax("/customer/list", {})
                .done(function (ret) {
                    that.customers = ret;
                })
                .fail(function () {})
                .always(function () {
                    that.isLoading = false;
                });
        },
        onAdd() {
            let that = this;
            if (that.isLoading) {
                return;
            }
            that.$prompt("客户名称").then((r) => {
                let value = r.value.replace(/^\s+|\s+$/g, "");
                let existed = false;
                for (let i = 0; i < that.customers.length; i++) {
                    let customer = that.customers[i];
                    if (customer.name === value) {
                        existed = true;
                        break;
                    }
                }
                if (existed) {
                    that.$alert("已存在同名客户");
                } else {
                    that.isLoading = true;
                    that.$ajax("/customer/add", { name: value })
                        .done(function () {
                            that.isLoading = false;
                            that.getList();
                        })
                        .fail(function () {
                            that.isLoading = false;
                        })
                        .always(function () {});
                }
            });
        },
        onEdit(customer) {
            let that = this;
            if (that.isLoading) {
                return;
            }
            let existed = false;
            that.$prompt("请输入新的客户名", "编辑", {
                inputValue: customer.name,
            }).then((r) => {
                let value = r.value.replace(/^\s+|\s+$/g, "");
                if (value === customer.name) {
                    return;
                }
                for (let i = 0; i < that.customers.length; i++) {
                    let item = that.customers[i];
                    if (item.id !== customer.id && item.name === value) {
                        existed = true;
                        break;
                    }
                }
                if (existed) {
                    that.$alert("已存在同名客户");
                } else {
                    that.isLoading = true;
                    that.$ajax("/customer/update", {
                        id: customer.id,
                        name: value,
                    })
                        .done(function () {
                            that.isLoading = false;
                            that.getList();
                        })
                        .fail(function () {
                            that.isLoading = false;
                        })
                        .always(function () {});
                }
            });
        },
    },
    filters: {},
};
</script>
